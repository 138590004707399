@import 'assets/styles/variables';

.converter__container {
  min-height: calc(100vh - 10rem);
  @media (max-width: $mobile) { padding: $vpadding-section-mobile $hpadding-mobile; }
  @media (min-width: $mobile) and (max-width: $desktop_sm) { padding: $vpadding-section-sm $hpadding-desktop-sm; }
  @media (min-width: $desktop_sm) { padding: $vpadding-section-md $hpadding-desktop-md; }
  .converter__title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: $btw-elements;
    text-align: center;
  }
  .converter__form {
    .converter__textarea {
      background: rgb(247, 252, 255);
      border: 2px solid black;
      border-radius: 5px;
      display: block;
      outline: none;
      resize: none;
      width: 100%;

      @media (max-width: $mobile) { 
        height: 15rem;
        &::placeholder { line-height: 14rem; }
      }
      @media (min-width: $mobile) and (max-width: $desktop_sm) { 
        height: 22rem;
        &::placeholder { line-height: 21rem; }
      }
      @media (min-width: $desktop_sm) { 
        height: 25rem;
        &::placeholder { line-height: 24rem; }
      }
      &::placeholder {
      font-size: 2rem;
      text-align: center;
      }
    }
    .converter__clearbutton{
      background: none;
      border: none;
      color: blue;
      float: right;
      font-size: $font-size;
      &:hover{
        cursor: pointer;
      }
    }
    .converter__selectlabel{
      display: block;
      margin-bottom: 0.5rem;
      margin-top: $btw-elements;
      font-size: $font-size;
    }
    .converter__numtoignore{
      display: none;
      font-size: $font-size;
      margin-top: $btw-elements;
      width: 100% ;
      .converter__numtoignore--label {
        display: block;
        margin-bottom: 0.5rem;
      }
      .converter__numtoignore--inputcontainer {
        align-items: center;
        border: 0.3px solid rgb(204, 204, 204);
        border-radius: 5px;
        display: flex;
        height: 3.5rem;
        width: 100%;
        .converter__numtoignore--input {
          border: none;
          border-radius: 5px;
          font-size: $font-size;
          height: 100%;
          text-align: center;
          width: 5rem;
          &:focus{
            outline: 2px solid rgb(98, 116, 255);
          }
        }
      }
    }
    .converter__chosenopt {
      display: inline-block;
    }
    .converter__wordslabel {
      display: block;
      margin-bottom: 0.5rem;
      margin-top: $btw-elements;
      font-size: $font-size;
    }
    .converter__wordsinput {
      border: 0.3px solid rgb(204, 204, 204);
      border-radius: 5px;
      font-size: $font-size;
      height: 3.5rem;
      padding-left: 1rem;
      width: 100%;
      &:focus{
        outline: 2px solid rgb(98, 116, 255);
      }
    }
    .converter__span {
      display: block;
      font-size: 1rem;
      margin-top: 0.3rem;
    }
    .converter__submit {
      background: rgb(0, 94, 255);
      border: none;
      border-radius: 5px;
      color: white;
      display: block;
      margin: $btw-elements 0;
      height: 4rem;
      font-size: 1.5rem;
      width: 100%;
      &:hover{
        cursor: pointer;
      }
    }
  }
}





