@import 'assets/styles/variables';

.footer__container {
  @media (max-width: $mobile) { padding: 0 $hpadding-mobile; }
  @media (min-width: $mobile) and (max-width: $desktop_sm) { padding: 0 $hpadding-desktop-sm; }
  @media (min-width: $desktop_sm) { padding: 0 $hpadding-desktop-md; }

  align-items: center;
  background: $menuColor;
  display: flex;
  justify-content: space-between;
  height: 5rem;

  .footer__item { 
    color: white;
    font-size: $font-size;
    word-wrap: break-word;
    @media (max-width: 400px) { width: 12rem }
  }
}