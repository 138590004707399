$hpadding-mobile: 2rem;
$hpadding-desktop-sm: 10rem;
$hpadding-desktop-md: 20rem;
$vpadding-section-mobile: 1rem;
$vpadding-section-sm: 2rem;
$vpadding-section-md: 2rem;
$btw-elements: 1.5rem;
$font-size: 1.3rem;
$menuColor: #4d4d4d;

// breakpoints
$mobile: 768px;
$desktop_sm: 980px;
$desktop_md: 1280px;