@import 'assets/styles/variables';
@import './assets/menuVars.scss';
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&display=swap');

.stheader {
  background: $menuColor;
  display: flex;
  justify-content: space-between;

  @media (max-width: $mobile) {
    padding: $menupadding-mobile $hpadding-mobile;
  }
  @media (min-width: $mobile) and (max-width: $desktop_sm) {
    padding: $menupadding-desktop $hpadding-desktop-sm;
  }
  @media (min-width: $desktop_sm) {
    padding: $menupadding-desktop $hpadding-desktop-md;
  }
}